.ant-layout-header {
    display: flex;
    align-items: center;
    background-image: url(../img/header-back.png) !important;
    background-size: 100% !important;
}

.ant-layout-footer {
    background: '#0162d0';
    color: white;
    background-image: url(../img/header-back.png) !important;
    background-size: 100% !important;
}

.logo-sm {
    display: flex;
    width: 60px;
}

.address-input {
    width: 60%;
    height: 40px;
}
  
.ant-layout-footer a {
    color: #ffffff !important;
}